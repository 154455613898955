import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col, Container, Table, Tooltip, OverlayTrigger, Modal, Form, Button, InputGroup } from 'react-bootstrap';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import './css/customTableStyles.css';
import { FaEdit, FaKey } from "react-icons/fa";
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

// Function to export data as Excel
const exportToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'data_user_ngpp.xlsx');
};

const MasterUserApproval = (username) => {
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [statuse, setStatuse] = useState('');
    const [reason, setReason] = useState('');
    const [tipeMitra, setTipeMitra] = useState(2); // State untuk tipe_mitra
    const uname = username['username'];

    const fetchData = (tipe) => {
        axios.get(`${process.env.REACT_APP_SERVER}/users`, {
            params: { tipe }
        })
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
            });
    };

   useEffect(() => {
        fetchData(tipeMitra); // Ambil data berdasarkan tipe_mitra
    }, [tipeMitra]); // Dependensi

    const data = React.useMemo(() => users.map((user, index) => ({ ...user, index: index + 1 })), [users]);
    

    const columns = React.useMemo(
        () => [
            { Header: 'NO', accessor: 'index', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'USER ID', accessor: 'user_id', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'USERNAME', accessor: 'username', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'NAMA PERUSAHAAN', accessor: 'nama_perusahaan', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'HANDPHONE', accessor: 'nomor_hp', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            { Header: 'APPROVER', accessor: 'approval', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },
            {
                Header: 'STATUS', accessor: 'status_submission', Cell: ({ cell: { value }, row }) => (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{row.original.reason}</Tooltip>}
                    >
                        <div style={{ textAlign: 'center' }}>{value}</div>
                    </OverlayTrigger>
                )
            },
            {
                Header: 'EVENT DATE', accessor: 'event_dt', Cell: ({ cell: { value }, row }) => (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>
                            <div className="text-start nowrap">
                                CR : {formatDate(row.original.created_at)}<br />
                                UP : {formatDate(row.original.updated_at)}
                            </div>
                        </Tooltip>}
                    >
                        <div style={{ textAlign: 'center' }}>{value}</div>
                    </OverlayTrigger>
                )
            },
            {
                Header: 'EDIT', accessor: 'actions', Cell: ({ row }) => (
                    <div style={{ textAlign: 'center' }}>
                        <FaEdit onClick={() => handleEditClick(row.original)} style={{ cursor: 'pointer' }} /> &nbsp;
                        <FaKey onClick={() => handleResetPassword(row.original)} style={{ cursor: 'pointer' }} />
                    </div>
                )
            }
        ],
        []
    );

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const handleEditClick = (user) => {
        setSelectedUser(user);
        setStatuse(user.status_submission);
        setReason(user.reason);
        setShowModal(true);
    };

    const handleResetPassword = (user) => {
        Swal.fire({
            title: `Apakah anda yakin akan melakukan reset password ke user ${user.username}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya, reset',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#d33',  // Mengubah warna tombol konfirmasi menjadi merah
            cancelButtonColor: '#3085d6',  // Mengubah warna tombol batal
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`${process.env.REACT_APP_SERVER}/users_reset/${user.user_id}`)
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Berhasil!',
                            text: 'Reset password berhasil!',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#d33'  // Mengubah warna tombol OK menjadi merah
                        });
                    })
                    .catch(error => {
                        console.error('There was an error updating the user!', error);
                        Swal.fire({
                            icon: 'error',
                            title: 'Gagal!',
                            text: 'Terjadi kesalahan saat mereset password.',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#d33'  // Mengubah warna tombol OK menjadi merah
                        });
                    });
            }
        });
    };

    const handleSave = () => {
        axios.put(`${process.env.REACT_APP_SERVER}/users/${selectedUser.user_id}`, { statuse, reason, uname })
            .then(response => {
                // Update user list
                setUsers(users.map(user =>
                    user.user_id === selectedUser.user_id ? { ...user, approval: uname, status_submission: statuse, reason } : user
                ));
                setShowModal(false);
            })
            .catch(error => {
                console.error('There was an error updating the user!', error);
            });
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state: { pageIndex, pageSize, globalFilter },
        setPageSize,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const handleChange = (e) => {
        const { value } = e.target;
        setTipeMitra(Number(value)); // Perbarui tipe_mitra
    };


    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF', overflowY: 'auto' }}>
            <header className="mb-4">
                <h5 className="text-danger">User Management</h5>
                Anda dapat melakukan Approval status user atau melakukan Rejected serta menghapus user
            </header>

            <Container>
                <Row className="mb-3">
                    <Col xs={5}>
                        <InputGroup size="sm" className="me-2">
                            <Form.Control
                                value={globalFilter || ''}
                                onChange={e => setGlobalFilter(e.target.value || undefined)}
                                placeholder={`Search all columns...`}
                                className="custom-filter-input"
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={3}>
                        <Button onClick={() => exportToExcel(data)} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>
                            Export to Excel
                        </Button>
                    </Col>
                    <Col xs={4} className="p-0">
                            <Form.Select size="sm" name="tipe_mitra" onChange={handleChange} required style={{ width: '96%', display: 'inline-block' }}>
                                <option value="2">AGGREGATOR</option>
                                <option value="3">AGGREGATOR & SWITCHING</option>
                                <option value="4">SWITCHING</option>    
                                <option value="1">TRADITIONAL</option>
                            </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table {...getTableProps()} striped bordered hover className="custom-table" style={{ fontSize: '12px' }}>
                            <thead className="custom-thead">
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className={column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''}
                                            >
                                                {column.render('Header')}
                                                <span className="sort-arrow"></span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => (
                                                <td {...cell.getCellProps()} className="p-1">{cell.render('Cell')}</td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center">
                    <Col xs={8} className="d-flex align-items-center">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<<'}</button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<'}</button>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'>'}</button>
                        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className="btn btn-danger" style={{ fontSize: '12px' }}>{'>>'}</button>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center justify-content-end" style={{ fontSize: '12px' }}>
                        <span>Page {pageIndex + 1} of {pageOptions.length}</span>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center text-end">
                        <Form.Select value={pageSize} onChange={e => { setPageSize(Number(e.target.value)); }} style={{ fontSize: '12px' }}>
                            {[5, 10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </Form.Select>
                    </Col>
                </Row>
            </Container>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                value={statuse}
                                onChange={(e) => setStatuse(e.target.value)}
                            >
                                <option value="">Select a status</option>
                                <option value="APPROVED">Approved</option>
                                <option value="REJECTED">Rejected</option>
                                <option value="PENDING">Pending</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formBasicReason">
                            <Form.Label>Reason</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter reason"
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MasterUserApproval;
