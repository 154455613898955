import React, { useEffect, useState, useCallback  } from 'react';
import axios from 'axios';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import { Row, Col, Container, Table, Button, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { FaEdit, FaKey, FaEye } from "react-icons/fa";
import './css/customTableStyles.css';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

// Function to export data as Excel
const exportToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'LIST_NO_PKS.xlsx');
};

const MasterDokumenPKS = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const [tahunNGPP, setTahunNGPP] = useState(2024); // State untuk tipe_mitra

    const data = React.useMemo(() => users.map((user, index) => ({ ...user, index: index + 1 })), [users]);
    const data2 = React.useMemo(() => users.map((user, index) => ({ Perusahaan: user.nama_perusahaan,"NO PKS": user.no_pks })),
    [users]);

    /*const FormUploadPKS = () => {
        navigate(`/form-upload-pks`);
    };*/

    const fetchData = (tahun) => {
        axios.get(`${process.env.REACT_APP_SERVER}/data-pks`, {
            params: { tahun }
        })
            .then(response => {
                setUsers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
            });
    };

    useEffect(() => {
        fetchData(tahunNGPP); // Ambil data berdasarkan tipe_mitra
    }, [tahunNGPP]); // Dependensi

    const handleViewDetail = useCallback((user) => {
        navigate(`/master-pks-detail/${user.pks_id}/${user.nama_perusahaan}`);
    }, [navigate]); 


    const FormUploadPKS = useCallback((user) => {
        navigate(`/form-upload-pks/${user.pks_id}/${user.nama_perusahaan}`);
    }, [navigate]); 
    
    const columns = React.useMemo(
        () => [
            { Header: 'NO', accessor: 'index', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'center' }}>{value}</div> },
            { Header: 'Nama Perusahaan', accessor: 'nama_perusahaan', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },                     
            { Header: 'NO PKS', accessor: 'no_pks', Cell: ({ cell: { value } }) => <div style={{ textAlign: 'left' }}>{value}</div> },                                      
            {
                Header: 'ACTION', accessor: 'actions', Cell: ({ row }) => (
                    <div style={{ textAlign: 'center' }}>
                        <FaEye onClick={() => handleViewDetail(row.original)} style={{ cursor: 'pointer' }} /> &nbsp;
                        <FaEdit onClick={() => FormUploadPKS(row.original)} style={{ cursor: 'pointer' }} /> &nbsp;
                    </div>
                )
            },
        ],
        [handleViewDetail]
    );   

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state: { pageIndex, pageSize, globalFilter  },
        setPageSize,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage, 
        setGlobalFilter,       
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const handleChange = (e) => {
        const { value } = e.target;
        setTahunNGPP(Number(value)); // Perbarui tipe_mitra
    };

    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF', overflowY: 'auto' }}>
             <header className="mb-4">
                <h5 className="text-danger">Tracker PKS</h5>
            </header>
            <Container> 
            <Row className="mb-3">
                    <Col xs={5}>
                        <InputGroup size="sm" className="me-2">
                            <Form.Control
                                value={globalFilter || ''}
                                onChange={e => setGlobalFilter(e.target.value || undefined)}
                                placeholder={`Search all columns...`}
                                className="custom-filter-input"
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={3}>
                    <Button onClick={() => exportToExcel(data2)} className="btn btn-success me-2" style={{ fontSize: '12px' }}>
                                                <b>List NO PKS</b>
                                            </Button>
                    </Col>
                    <Col xs={4} className="p-0">
                            <Form.Select size="sm" name="tahun_ngpp" onChange={handleChange} required style={{ width: '96%', display: 'inline-block' }}>
                                <option value="2024">2024</option>
                                <option value="2022">2022</option>
                            </Form.Select>
                    </Col>
                </Row>               
                <Row>
                    <Col>
                        <Table {...getTableProps()} striped bordered hover className="custom-table" style={{ fontSize: '12px' }}>
                            <thead className="custom-thead text-center">
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                className={`${column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''} text-center`}
                                            >
                                                {column.render('Header')}
                                                <span className="sort-arrow"></span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => (
                                                <td {...cell.getCellProps()} className="p-1">{cell.render('Cell')}</td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

            

                <Row className="d-flex align-items-center">
                    <Col xs={8} className="d-flex align-items-center">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<<'}</button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'<'}</button>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className="btn btn-danger me-2" style={{ fontSize: '12px' }}>{'>'}</button>
                        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className="btn btn-danger" style={{ fontSize: '12px' }}>{'>>'}</button>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center justify-content-end" style={{ fontSize: '12px' }}>
                        <span>Page {pageIndex + 1} of {pageOptions.length}</span>
                    </Col>
                    <Col xs={2} className="d-flex align-items-center">
                        <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))} className="form-select ms-2" style={{ fontSize: '12px' }}>
                            {[5, 10, 20].map(size => (
                                <option key={size} value={size}>Show {size}</option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </Container>            
        </div>
    );
};

export default MasterDokumenPKS;
