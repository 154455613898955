import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Alert, Form, Button, Row, Col, Container } from 'react-bootstrap';

const MAX_FILE_SIZE_MB = 10; // Maximum file size in MB

const FormDokumenSwitching_3 = ({ uid, tipe, username }) => {
    const [formData, setFormData] = useState({
        ijin_usaha: null,
        struktur_organisasi: null,
        asset_perusahaan: null,
        file_dokumen_legalitas: null,
        file_dokumen_validasi: null,
    });

    const [IsFileRequired, setIsFileRequired] = useState(true); 
    const [disabledForm, setdisabledForm] = useState(false); 
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER}/getDokumenSwitching3`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username })
        })
        .then(response => response.json())
        .then(data => {
            setdisabledForm(true); // DISABLE WEB
            if(uid==86 || uid==140 || uid==152 || uid==131 || uid==132 || uid>150){
                setdisabledForm(false); // Menjadikan Logo tidak wajib jika sudah ada
            }
            if(data.length > 0){
                setIsFileRequired(false);
               /* if(data[0].status=='PENDING' || data[0].status=='APPROVED'){
                        setdisabledForm(true); // Menjadikan Logo tidak wajib jika sudah ada
                }*/
            }
        })
        .catch(error => console.error('Error fetching data:', error));
    }, [username]);

    const handleChange = (e) => {
        const { name, files } = e.target;

        if ((name === 'ijin_usaha' || name === 'struktur_organisasi' || name === 'asset_perusahaan' || name === 'file_dokumen_legalitas' || name === 'file_dokumen_validasi') && files.length > 0) {
            const file = files[0];
            if (file.type !== 'application/pdf') {
                Swal.fire({
                    icon: 'warning',
                    title: 'Peringatan',
                    text: 'Hanya file .pdf yang diperbolehkan!',
                });
                return;
            }
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Peringatan',
                    text: `Ukuran file tidak boleh lebih dari ${MAX_FILE_SIZE_MB} MB!`,
                });
                return;
            }

            setFormData((prevData) => ({
                ...prevData,
                [name]: file,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const requiredFiles = [
            'ijin_usaha',
            'asset_perusahaan'
        ];
 
        if(tipe==2){
            requiredFiles.push('file_dokumen_legalitas');
        }

        for (const fileName of requiredFiles) {
            if (!formData[fileName] && IsFileRequired==true) {
                Swal.fire({
                    icon: 'warning',
                    title: 'File tidak lengkap',
                    text: `File ${fileName} harus diunggah!`,
                });
                setLoading(false);
                return;
            }
        }

        const usernamePart = `${uid}_${username.split('@')[0]}`;
        const data = new FormData();
        data.append('username', username);

        Object.keys(formData).forEach((key) => {
            if (formData[key]) {
                const renamedFile = new File([formData[key]], `${usernamePart}_${key}.pdf`, {
                    type: formData[key].type,
                });
                data.append(key, renamedFile);
            }
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/uploadDokumenSwitching_3`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Data berhasil disimpan',
                    text: 'Data perusahaan berhasil disimpan',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal menyimpan data',
                    text: 'Gagal menyimpan data perusahaan',
                });
            }
        } catch (error) {
            console.error('Terjadi kesalahan:', error);
            Swal.fire({
                icon: 'error',
                title: 'Terjadi kesalahan',
                text: 'Terjadi kesalahan saat menyimpan data perusahaan',
            });
        }
        setLoading(false);
    };
    
    return (
        <div style={{ height: '70vh', overflowY: 'auto' }}>
            <p className="mb-4"><b>ASPEK LEGALITAS SWITCHING</b></p> 
            <Alert key="info" variant="info">Semua file yang di upload harus berformat <b>.pdf</b> dengan max size per file 10Mb</Alert>           
            <Form onSubmit={handleSubmit}>
                <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-2'>
                        <Col xs={12} className="px-0">
                            <span style={{ fontSize: '12px' }}>IJIN USAHA<span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="ijin_usaha" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Ijin usaha berbasis risiko sektor pos, telekomunikasi dan sistem dan transaksi elektronik sesuai Peraturan Menteri Komunikasi dan Informatika Nomor 3 Tahun 2021 </p>
                        </Col>
                        {/*tipe==4 ?
                        <><Col xs={12} className="p-0">
                            <span style={{ fontSize: '12px' }}>STRUKTUR ORGANISASI  <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }}  required={IsFileRequired} name="struktur_organisasi" onChange={handleChange} disabled={disabledForm} />
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}><br/></p>
                        </Col></>
                        : ''*/}
                        <Col xs={12} className="p-0">
                            <span style={{ fontSize: '12px' }}>ASSET PERUSAHAAN <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }}  required={IsFileRequired} name="asset_perusahaan" onChange={handleChange} disabled={disabledForm}/> 
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}><br/></p>
                        </Col>
                        <Col xs={12} className="p-0">
                            <span style={{ fontSize: '12px' }}>DOKUMEN VALIDASI STANDARISASI KESISTEMAN MITRA MODERN </span>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_dokumen_validasi" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}><br/></p>
                        </Col>
                        {tipe==2 ? 
                        <Col xs={12} className="p-0">
                            <span style={{ fontSize: '12px' }}>DOKUMEN LEGALITAS/KERJAASMA, JIKA MENUNJUK PIHAK KETIGA <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" placeholder="" style={{ width: '95%' }} name="file_dokumen_legalitas" onChange={handleChange} required={IsFileRequired} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}> <br/></p>
                        </Col> : '' }
                            
                        <Col xs={12} className="pt-4 px-4 text-end">
                            <Button type="submit" className="bg-warning" style={{ border: '0px solid black' }}>Simpan</Button>
                        </Col>
                    </Row>
                </Container>
                {loading && (
                            <div className="overlay">
                                <div className="loading">Loading...</div>
                            </div>
                        )}
            </Form>
        </div>
    );
}

export default FormDokumenSwitching_3;
